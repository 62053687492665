@import '~antd/lib/table/style/index';
@import '../../checkbox/styles/index';
@import '../../radio/styles/index';

.syt-antd-table-thead > tr > th,
.syt-antd-table-thead th.syt-antd-table-column-sort {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;

  background: @cws-neutral-10;
  border-bottom: 1px solid @cws-neutral-20;

  &:hover {
    background: @cws-neutral-20;
  }
}

/* -------- Borders -------- */

.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container {
  border-top: 1px solid @cws-neutral-20;
  border-left: 1px solid @cws-neutral-20;
}

.syt-antd-table.syt-antd-table-bordered > .syt-antd-table-container {
  & > .syt-antd-table-content > table > thead > tr > th,
  & > .syt-antd-table-header > table > thead > tr > th,
  & > .syt-antd-table-body > table > thead > tr > th,
  & > .syt-antd-table-content > table > tbody > tr > td,
  & > .syt-antd-table-header > table > tbody > tr > td,
  & > .syt-antd-table-body > table > tbody > tr > td,
  & > .syt-antd-table-content > table > tfoot > tr > th,
  & > .syt-antd-table-header > table > tfoot > tr > th,
  & > .syt-antd-table-body > table > tfoot > tr > th,
  & > .syt-antd-table-content > table > tfoot > tr > td,
  & > .syt-antd-table-header > table > tfoot > tr > td,
  & > .syt-antd-table-body > table > tfoot > tr > td {
    border-right: 1px solid @cws-neutral-20;
  }
}

.syt-antd-table-tbody > tr > td {
  border-bottom: 1px solid @cws-neutral-20;
}

/* -------- Rows -------- */

.syt-antd-table-row:hover {
  > td {
    background: @cws-blue-10 !important;
  }
}

.syt-antd-table-row-selected {
  > td {
    background: @cws-neutral-10 !important;
  }
}


/* -------- Cells -------- */

.syt-antd-table-cell.syt-antd-table-selection-column .syt-antd-radio-inner::after {
  top: 3px;
  left: 3px;
}

.syt-antd-table-cell.syt-antd-table-column-sort {
  background: @cws-neutral-00;
}
