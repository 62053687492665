@import '~antd/lib/checkbox/style/index';

input.syt-antd-checkbox-input {
  margin: 0 !important;
  color: @cws-neutral-100;
}

.syt-antd-checkbox-inner {
  background-color: @cws-neutral-00;
  border: 1px solid @cws-neutral-30;
  box-sizing: border-box;
  border-radius: 2px;
}

.syt-antd-checkbox-checked .syt-antd-checkbox-inner {
  border-color: @cws-blue-50 !important;
  background-color: @cws-blue-50 !important;
}

.syt-antd-checkbox:hover .syt-antd-checkbox-inner,
.syt-antd-checkbox-wrapper:hover .syt-antd-checkbox-inner,
.syt-antd-checkbox-input:focus + .syt-antd-checkbox-inner {
  border-color: @cws-blue-50 !important;
}

.syt-antd-checkbox-input:focus + .syt-antd-checkbox-inner {
  box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
}

.syt-antd-checkbox:active .syt-antd-checkbox-inner,
.syt-antd-checkbox-wrapper:active .syt-antd-checkbox-inner {
  border-color: @cws-blue-60 !important;
  background-color: @cws-blue-60 !important;
}

.syt-antd-checkbox-checked::after {
  border: none;
}

.syt-antd-checkbox-inner::after {
  top: 42%;
  left: 18%;
}

.syt-antd-checkbox-indeterminate .syt-antd-checkbox-inner::after {
  background-color: @cws-blue-50 !important;
}

.syt-antd-checkbox-indeterminate.syt-antd-checkbox-disabled .syt-antd-checkbox-inner::after {
  background-color: @cws-neutral-40 !important;
}

.syt-antd-checkbox-disabled .syt-antd-checkbox-inner::after {
  border-color: @cws-neutral-40 !important;
}

.syt-antd-checkbox-input:disabled + .syt-antd-checkbox-inner {
  background-color: @cws-neutral-10 !important;
  border-color: @cws-neutral-30 !important;
}

.syt-antd-checkbox-disabled + span {
  color: @cws-neutral-40;
  cursor: not-allowed;
}
