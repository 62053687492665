@import '~antd/lib/steps/style/index';


.syt-antd-steps-item-process .syt-antd-steps-item-container {
  .syt-antd-steps-item-content {
    .syt-antd-steps-item-title::after {
      background: @cws-neutral-50;
    }
  }
  .syt-antd-steps-item-subtitle {
    transition: none;
  }
  .syt-antd-steps-item-tail::after {
    background: @cws-neutral-50;
  }
} 

.syt-antd-steps-dot .syt-antd-steps-item-tail::after, .syt-antd-steps-dot.syt-antd-steps-small .syt-antd-steps-item-tail::after{
  height: 1px;
}

.syt-antd-steps-item-wait .syt-antd-steps-item-icon {
  border-color: @cws-neutral-40;
 .syt-antd-steps-icon {
    color:  @cws-neutral-40;
    .syt-antd-steps-icon-dot {
      color:  @cws-neutral-40;
    }
  }
}

.syt-antd-steps-dot .syt-antd-steps-item-icon .syt-antd-steps-icon {
  top: 8px;
} 

.syt-antd-steps-item-finish {
  .syt-antd-steps-item-container {
    .syt-antd-steps-item-content {
      .syt-antd-steps-item-description {
        color:  @cws-neutral-50;
      }
    } 
  } 
}

.syt-antd-steps-item-title{
  transition: none !important;
}
.syt-antd-steps-item-subtitle {
  color: inherit;
  transition: none !important;
}

.syt-antd-steps-item-finish .syt-antd-steps-item-container .syt-antd-steps-item-content {
  .syt-antd-steps-item-title {
    color: @cws-neutral-60;
  }
  .syt-antd-steps-item-description{
    color:  @cws-neutral-60;
  }
}