@import '~antd/lib/alert/style/index';

.custom-alert-icon {
  position: static;
  margin-right: 19px;
  margin-left: -13px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.empty-description {
  height: 48px; 
  .syt-antd-alert-message {
    position: absolute;
    top: 13px;
  }
  .syt-antd-alert-close-icon{
    font-size: 14px;
    position: absolute;
    top: 17px;
  }
} 

.syt-antd-alert-with-description {
  color: @cws-neutral-60;
}

.syt-antd-alert-success {
  background-color: @cws-green-10;
  border: 1px solid @cws-green-30;
}

.syt-antd-alert-warning {
  background-color: @cws-yellow-10;
  border: 1px solid @cws-yellow-30;
} 

.syt-antd-alert.syt-antd-alert-no-icon .syt-antd-alert-close-icon {
    top: 16px;
}