@import '~antd/lib/select/style/index';

.syt-antd-select.syt-antd-select-lg {
  font-size: 16px;
}


.syt-antd-select-single:not(.syt-antd-select-customize-input) .syt-antd-select-selector {
  border-color: @cws-neutral-30;
}

.syt-antd-select-multiple .syt-antd-select-selector {
  border-color: @cws-neutral-30; 
}

div.syt-antd-select-item.syt-antd-select-item-group {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: @cws-neutral-60;
}

.syt-antd-select-focused {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
}